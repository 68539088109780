export const env = {
  VERSION: __env.VERSION,
  IS_SWAN_MODE: __env.IS_SWAN_MODE,
  BANKING_URL: __env.BANKING_URL,
  PAYMENT_URL: __env.PAYMENT_URL,
  APP_TYPE: __env.SWAN_ENVIRONMENT,
  PLACEKIT_API_KEY: __env.CLIENT_PLACEKIT_API_KEY,
  TGGL_API_KEY: __env.TGGL_API_KEY,
  // Assoconnect Feature flags
  ASC_DISPLAY_FEATURE_ICT: __env.ASC_DISPLAY_FEATURE_ICT,
  ASC_DISPLAY_FEATURE_CHAT: __env.ASC_DISPLAY_FEATURE_CHAT,
};
